import React from "react"
import { chakra, Box, Button, Flex, Link, SimpleGrid } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  PrimaryPagesHero,
  SectionHeading,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { Seo } from "../components/elements"

const ChakraImg = chakra(GatsbyImage)

const Glbcrs = ({ data, pageContext }) => {
  const page = data.datoCmsGlbcrsPage
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page?.seo?.title || ''}
        description={page?.seo?.description || ' '}
        image={page?.seo?.image && page.seo.image.url}
      />

      <PrimaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page?.title}
        />
      </PrimaryPagesHero>

      <Main>
        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box
                sx={{ width: ["full", "full", 2 / 3] }}
                dangerouslySetInnerHTML={{
                  __html: page?.introContentNode?.childMarkdownRemark.html,
                }}
              />
              <Box bg="lightgray" width={["full", "full", 1 / 3]} p={[3, 3, 6]}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page?.infoNode?.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>

        {/* Speakers */}
        <Section bg="lightgray">
          <Container>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box width="full">
                <SectionHeading textAlign="center" id="speakers">
                  Featured Keynote Speakers
                </SectionHeading>
              </Box>

              <Flex
                justifyItems={"space-between"}
                width={["full", "full", 2 / 3]}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
                mx={"auto"}
              >
                {page?.speakers?.map((speaker) => (
                  <Box
                    px={3}
                    key={speaker.id}
                    mx="auto"
                    textAlign={"center"}
                    w={["full", "full", "50%"]}
                  >
                    <ChakraImg
                      image={speaker?.photo?.gatsbyImageData}
                      alt={speaker?.name}
                      borderRadius="full"
                      mx="auto"
                      width={[32, 32, 64]}
                      height={[32, 32, 64]}
                    />

                    <Box mx="auto" textAlign={"center"} mt={3} w={["full"]}>
                      <Box as="p" fontSize={["lg"]} fontWeight={"bold"}>
                        {speaker?.name}
                      </Box>
                      <Box as="p" fontSize={["sm"]}>
                        {speaker?.title}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Flex>

              <Flex
                w={"full"}
                mt={[9, 9, 12]}
                flexWrap={"wrap"}
                justifyContent={"center"}
              >
                <Link
                  href={page?.agenda}
                  style={{ textDecoration: "none" }}
                  m={[2]}
                  w={["full", "full", 1 / 4]}
                >
                  <Button
                    as="span"
                    variant="solid"
                    colorScheme="blue"
                    sx={{ w: "full" }}
                  >
                    Agenda
                  </Button>
                </Link>
              </Flex>
              
            </Flex>
          </Container>
        </Section>

        {/* Sponsors */}
        <Section>
          <Container>
            <Box width="full" mb={[12, 12, 16]}>
              <SectionHeading textAlign="center" id="hosted-by">
                Co-sponsored and hosted by
              </SectionHeading>
              <Box
                w={["full", "full", 96]}
                textAlign={"center"}
                mx="auto"
                px={[12, 12, 6]}
              >
                <img
                  src="https://www.datocms-assets.com/30187/1680817875-01-wvucancerinstitute.png"
                  alt="wvu cancer institute"
                />
              </Box>
            </Box>

            <Box width="full">
              <SectionHeading textAlign="center" id="sponsors">
                Sponsors
              </SectionHeading>
            </Box>
            <SimpleGrid columns={[2, 2, 4]} gridGap={[6, 6, 9]}>
              {page?.sponsors?.map((sponsor) => (
                <Box key={sponsor.id}>
                  <ChakraImg
                    image={sponsor?.sponsorName?.gatsbyImageData}
                    alt={sponsor?.sponsorName?.alt || "sponsor"}
                    mx="auto"
                    layout="full"
                  />
                </Box>
              ))}
            </SimpleGrid>
          </Container>
        </Section>

        <Section>
          <Container>
            <Box>
              <Box
                mx="auto"
                sx={{ width: ["full", "full", 2 / 3] }}
                dangerouslySetInnerHTML={{
                  __html: page?.hotelNode?.childMarkdownRemark.html,
                }}
              />
            </Box>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default Glbcrs

export const query = graphql`
  {
    datoCmsGlbcrsPage {
      title
      slug
      agenda
      registration
      submitAnAbstract
      introContentNode {
        childMarkdownRemark {
          html
        }
        id
      }
      infoNode {
        childMarkdownRemark {
          html
        }
        id
      }
      hotelNode {
        childMarkdownRemark {
          html
        }
        id
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      speakers {
        title
        id
        name
        photo {
          gatsbyImageData(
            width: 300
            imgixParams: { fit: "crop", crop: "focalpoint" }
          )
          alt
          title
          customData
        }
      }
      sponsors {
        id
        sponsorName {
          gatsbyImageData(width: 300)
        }
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
